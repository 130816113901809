const RouteFragment = {
    GLOBAL_API: "global-api",
    API: "api",
    ORGANIZATIONS: "organizations",
    COPY_ORGANIZATION: "copy-organization",
    STORAGE_SERVERS: "storage-servers",
    USERS: "users",
    USER_GROUPS: "user-groups",
    RISKS: "risks",
    ENTITIES: "entities",
    ALL_CONTROLS: "all_controls",
    CONTROLS: "controls",
    CONTROL_PROGRAMS: "control-programs",
    TESTING_PROGRAMS: "testing-programs",
    TESTING_CONTROLS: "testing-controls",
    TESTING_ACTIVITIES: "testing-activities",
    DATASHEET_ASSIGNMENTS: "datasheet-assignments",
    RESOURCE_COUNT: "resource-count",
    SAVED_REPORTS: "saved-reports",
    PUSH_REPORTS: "push-reports",
    REPORTS: "reports",
    RESPONSIBLE: "responsible",
    RECIPIENT: "recipient",
    TAGS: "tags",
    TAG_CATEGORIES: "tag-categories",
    STATS: "stats",
};

const GlobalAPIRoute = {
    ROOT: `/${RouteFragment.GLOBAL_API}`,
    SSO: `/${RouteFragment.GLOBAL_API}/single-sign-on`,
    ORGANIZATIONS: `/${RouteFragment.GLOBAL_API}/${RouteFragment.ORGANIZATIONS}`,
    COPY_ORGANIZATION: `/${RouteFragment.GLOBAL_API}/${RouteFragment.COPY_ORGANIZATION}`,
    STORAGE_SERVERS: `/${RouteFragment.GLOBAL_API}/${RouteFragment.STORAGE_SERVERS}`,
    ORGANIZATIONS_ACTIVE_USERS: `/${RouteFragment.GLOBAL_API}/${RouteFragment.ORGANIZATIONS}/active-users`,
    CALENDAR_TEMPLATES: `/${RouteFragment.GLOBAL_API}/calendar-templates`,
    ORGANIZATIONS_STATS_USERS: `/${RouteFragment.GLOBAL_API}/${RouteFragment.ORGANIZATIONS}/${RouteFragment.STATS}/${RouteFragment.USERS}`,
};

const GlobalWebRoute = {
    ORGANIZATIONS: `/${RouteFragment.ORGANIZATIONS}`,
    STORAGE_SERVERS: `/${RouteFragment.STORAGE_SERVERS}`,
};

const WebRoute = {
    ROOT: "/",
    REGISTER: "/register",
    RESET_PASSWORD: "/reset-password",
    FACE_LIFT: "/face-lift",
    EVENT_LOG: `/events`,
    PROFILE: `/profile`,
    SINGLE_SIGN_ON_CONFIGURATION: `/single-sign-on-configuration`,
    FINANCIAL_YEAR: `/financial-year`,
    USERS: `/${RouteFragment.USERS}`,
    USER_GROUPS: `/${RouteFragment.USER_GROUPS}`,
    CONTROLS: `/${RouteFragment.CONTROLS}`,
    CONTROLS_NEW: `/${RouteFragment.CONTROLS}/new`,
    CONTROLS_ALL: `/${RouteFragment.CONTROLS}/all`,
    CONTROLS_WEEKLY: `/${RouteFragment.CONTROLS}/weekly`,
    CONTROLS_MONTHLY: `/${RouteFragment.CONTROLS}/monthly`,
    CONTROLS_TWO_MONTHS: `/${RouteFragment.CONTROLS}/two-months`,
    CONTROLS_QUARTERLY: `/${RouteFragment.CONTROLS}/quarterly`,
    CONTROLS_HALF_YEARLY: `/${RouteFragment.CONTROLS}/half-yearly`,
    CONTROLS_YEARLY: `/${RouteFragment.CONTROLS}/yearly`,
    CONTROLS_MANUAL: `/${RouteFragment.CONTROLS}/manual`,
    TESTING_PROGRAMS: `/${RouteFragment.TESTING_PROGRAMS}`,
    TESTING_PROGRAMS_NEW: `/${RouteFragment.TESTING_PROGRAMS}/new`,
    TESTING_CONTROLS: `/${RouteFragment.TESTING_CONTROLS}`,
    TESTING_CONTROLS_NEW: `/${RouteFragment.TESTING_CONTROLS}/new`,
    TESTING_ACTIVITIES: `/${RouteFragment.TESTING_ACTIVITIES}`,
    REPORTS_ALL: `/${RouteFragment.REPORTS}`,
    REPORTS_STATUS: `/${RouteFragment.REPORTS}/status`,
    REPORTS_WORKLOAD: `/${RouteFragment.REPORTS}/workload`,
    REPORTS_CLASSIFICATION: `/${RouteFragment.REPORTS}/classification`,
    REPORTS_DATASHEET: `/${RouteFragment.REPORTS}/datasheet`,
    SETUP_2FA: `/setup-2fa`,
    TAGS: `/tags`,
    RISKS: `/risks`,
};

const APIRoute = {
    ROOT: `/${RouteFragment.API}`,
    SSO: `/${RouteFragment.API}/single-sign-on`,
    SSO_CONFIGURATION: `/${RouteFragment.API}/single-sign-on-configuration`,
    LOGIN_OPTIONS: `/${RouteFragment.API}/login-options`,
    SETUP_2FA: `/${RouteFragment.API}/setup-2fa`,
    USER_EVENTS: `/${RouteFragment.API}/user-events`,
    USERS: `/${RouteFragment.API}/${RouteFragment.USERS}`,
    USER_TAGS: `/${RouteFragment.API}/${RouteFragment.TAGS}`,
    USER_TAG_CATEGORIES: `${RouteFragment.API}/${RouteFragment.TAG_CATEGORIES}`,
    USER_GROUPS: `/${RouteFragment.API}/${RouteFragment.USER_GROUPS}`,
    RISKS: `/${RouteFragment.API}/${RouteFragment.RISKS}`,
    ENTITIES: `/${RouteFragment.API}/${RouteFragment.ENTITIES}`,
    ALL_CONTROLS: `/${RouteFragment.API}/${RouteFragment.ALL_CONTROLS}`,
    CONTROLS: `/${RouteFragment.API}/${RouteFragment.CONTROLS}`,
    CONTROL_PROGRAMS: `/${RouteFragment.API}/${RouteFragment.CONTROL_PROGRAMS}`,
    CONTROL_PROGRAM_TAGS: `/${RouteFragment.API}/${RouteFragment.CONTROL_PROGRAMS}/${RouteFragment.TAGS}`,
    CONTROL_PROGRAM_DATASHEET_ASSIGNMENTS: `/${RouteFragment.API}/${RouteFragment.CONTROL_PROGRAMS}`,
    CONTROLS_RESOURCE_COUNT: `/${RouteFragment.API}/${RouteFragment.CONTROLS}/${RouteFragment.RESOURCE_COUNT}`,
    FINANCIAL_YEAR: `/${RouteFragment.API}/financial-year`,
    CALENDARS: `/${RouteFragment.API}/calendars`,
    API_KEYS: `/${RouteFragment.API}/api-keys`,
    API_SCOPES: `/${RouteFragment.API}/api-scopes`,
    SHAREABLE_LINKS: `/${RouteFragment.API}/shareable-links`,
    SHAREABLE_LINKS_MIGRATION: `/${RouteFragment.API}/shareable-links/migrate`,
    EXTENSIONS: `/${RouteFragment.API}/extensions`,
    BLOBS: `/${RouteFragment.API}/blobs`,
    TIMEZONES: `/${RouteFragment.API}/timezones`,
    TESTING_PROGRAMS: `/${RouteFragment.API}/${RouteFragment.TESTING_PROGRAMS}`,
    TESTING_CONTROLS: `/${RouteFragment.API}/${RouteFragment.TESTING_CONTROLS}`,
};

const LEGACY_API_ROUTE = {
    REPORT: "/Report",
    DELETE_SAVED_REPORT: "/Report/ControlResultStatus/Saved/Delete",
};

const CUSTOM_REPORTS_HASH = "#custom-reports";
const PUSH_REPORTS_RESPONSIBLE_HASH = "#push-reports-responsible";
const PUSH_REPORTS_RECIPIENT_HASH = "#push-reports-recipient";

export {
    RouteFragment,
    GlobalAPIRoute,
    GlobalWebRoute,
    WebRoute,
    APIRoute,
    CUSTOM_REPORTS_HASH,
    PUSH_REPORTS_RESPONSIBLE_HASH,
    PUSH_REPORTS_RECIPIENT_HASH,
    LEGACY_API_ROUTE,
};
