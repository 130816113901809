import * as React from "react";

import ConfigProvider from "antd/es/config-provider";
import { Locale } from "antd/es/locale-provider";
// antd locale files
import daDK from "antd/es/locale-provider/da_DK";
import deDE from "antd/es/locale-provider/de_DE";
import enUS from "antd/es/locale-provider/en_US";
import esES from "antd/es/locale-provider/es_ES";
import frFR from "antd/es/locale-provider/fr_FR";
import * as Cookies from "es-cookie";
import * as i18n from "i18next";
import LangDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import LocizeBackend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import LastUsed from "locize-lastused";
import { useTranslation as useI18NextReactTranslation, initReactI18next, I18nextProvider, withTranslation, WithTranslation, WithTranslationProps, DefaultNamespace, Namespace, KeyPrefix, UseTranslationOptions, UseTranslationResponse } from "react-i18next";


import type { TranslationKeyValues, TranslationKey } from "types/i18n";

import languageDetector from "./languageDetector";

const i18next = i18n.default;

const locizeCookieProjectId = Cookies.get("locize_project_id");
const isLocizeEnabled = locizeCookieProjectId !== undefined;
const locizeOptions = locizeCookieProjectId ? {
    projectId: locizeCookieProjectId,
    apiKey: Cookies.get("locize_api_key"),
    referenceLng: "en",
} : undefined;

const banner = document.getElementById("exitLocizeModeBanner");
if (banner) {
    banner.style.display = locizeOptions && locizeOptions.apiKey ? "block" : "none";
    banner.addEventListener("click", () => {
        const domain = window.location.hostname.replace(/^[^\.]+\./, ""); // Parent
        Cookies.remove("locize_api_key", { domain: domain === "localhost" ? "" : "." + domain });
        location.reload();
    });
}

if (isLocizeEnabled) {
    i18next.use(LocizeBackend);
} else {
    i18next.use(HttpBackend);
}

const imperoLangDetector = new LangDetector();
imperoLangDetector.addDetector(languageDetector);

i18next
    .use(LastUsed)
    .use(locizePlugin)
    .use(imperoLangDetector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        fallbackLng: "en",
        supportedLngs: ["en", "da", "de", "es", "fr"],
        load: "currentOnly",
        react: {
            bindI18n: "languageChanged editorSaved",
        },
        detection: {
            // order and from where user language should be detected
            order: ["ImperoLangDetector", "navigator", "htmlTag"],
        },
        ns: ["translation"],
        defaultNS: "translation",
        backend: locizeOptions ? locizeOptions : {
            projectId: "locale",
            loadPath: "/assets/locales/{{ns}}.{{lng}}.json",
        },
        locizeLastUsed: locizeOptions,
        editor: locizeOptions && locizeOptions.apiKey ? {
            ...locizeOptions,
            onEditorSaved: (lng, ns) => {
                // reload that namespace in given language
                i18next.reloadResources(lng, ns, () => {
                    // trigger an event on i18n which triggers a rerender
                    // based on bindI18n below in react options
                    i18next.emit("editorSaved");
                });
            },
            enabled: true,
        } : undefined,
    }, err => {
        if (err) {return console.error(err);}
    });

const ANTD_LOCALE: { [key: string]: Locale } = {
    "en": enUS, // antd default locale is "en-US"
    "da": daDK,
    "de": deDE,
    "es": esES,
    "fr": frFR,
};

function getAntdLocal(i18n: i18n.i18n): Locale {
    const { language } = i18n;

    return ANTD_LOCALE[language];
}

function withAntdLocaleProvider<P extends WithTranslation>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Omit<Omit<P, keyof WithTranslationProps>, keyof WithTranslation> & WithTranslationProps> {
    const Translatable = (props: P) => (
        <ConfigProvider locale={getAntdLocal(i18next)}>
            <WrappedComponent {...props} />
        </ConfigProvider>
    );

    return withTranslation("translation")(Translatable);
}

function withI18nextProvider<P>(WrappedComponent: React.ComponentType<P & WithTranslationProps>): React.ComponentType<P & WithTranslationProps> {
    return (props: P & WithTranslationProps) => (
        <I18nextProvider i18n={i18next}>
            <WrappedComponent {...props} />
        </I18nextProvider>
    );
}

type TOptionsBase = Omit<i18n.TOptionsBase, "count">;

/**
* Usage of this function is deprecated.
* Use `useTranslation` from `utils/i18n` instead.
*/
export function t<K extends TranslationKey>(
    ...args:
    TranslationKeyValues[K] extends undefined ?
        [key: K, options?: TOptionsBase] :
        [key: K, options: TranslationKeyValues[K] & TOptionsBase]
): string {
    const [key, options] = args;
    // Use of i18next.t is restricted, to ensure use of this wrapper function
    // eslint-disable-next-line no-restricted-syntax
    const result = i18next.t(key, options);
    if (typeof result !== "string") {console.error(`${key} did not return string from t function!`);}
    return result;
}

export type TFunction = typeof t;
type OverrideUseTranslationResponse<
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined
> = Omit<UseTranslationResponse<N, TKPrefix>, "t"> & {
    t: TFunction,
};

export function useTranslation<
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined
>(ns?: N | Readonly<N>,
    options?: UseTranslationOptions<TKPrefix>): OverrideUseTranslationResponse<N, TKPrefix> {
    return useI18NextReactTranslation(ns, options);
}

export default function <P> (WrappedComponent: React.ComponentType<Omit<P & WithTranslation, keyof WithTranslation>>)/*: I'm unable to write that type -- adb */ {
    const componentWithTranslation = (props: P & WithTranslation) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { t, i18n, tReady, ...propsWithoutTranslation } = props;
        return <WrappedComponent {...propsWithoutTranslation} />;
    };
    return withI18nextProvider(withAntdLocaleProvider(componentWithTranslation));
}
