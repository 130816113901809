import { isBoolean, isFunction } from "lodash-es";

import { Storage } from "utils/storage";

// A simple feature flipper based on localStorage.
// It enables us to merge/ship not yet fully implemented functionality behind disabled feature flags, so we
// do not break the existing system behaviour.
// To familiarize with the concept, please read https://martinfowler.com/articles/feature-toggles.html

const DEV_ONLY_FEATURES = [
    "orgEntryStrictMode",
    "controlViewStrictMode",
];
const IS_DEV_ENV = process.env.NODE_ENV === "development";

// List of all known features.
export type FeatureKey = "showFeatureFlipper" | "orgEntryStrictMode" | "controlViewStrictMode" | "controlFreezeAtom" | "createTestRiskDirectory" | "controlTesting";

const FEATURES: Record<FeatureKey, FeatureDeclarationData> = {
    showFeatureFlipper: {
        description: "Enables toggling of the feature flipper. When disabled the button in the bottom-right corner is not shown.",
        defaultValue: () => SHOW_FEATURE_FLIPPER_DEFAULT_DOMAINS.includes(window.location.hostname) || IS_DEV_ENV,
    },
    orgEntryStrictMode: {
        description: "Enables React strict mode for the whole org application - not recommended",
        defaultValue: false,
    },
    controlViewStrictMode: {
        description: "Enables React strict mode for the control view - strongly recommended to keep enabled",
        defaultValue: true,
    },
    controlFreezeAtom: {
        description: "Freeze the atom state for the control page - use for debugging",
        defaultValue: false,
    },
    createTestRiskDirectory: {
        description: "Enables the creation of a test risk directory",
        defaultValue: false,
    },
    controlTesting: {
        description: "Enables the Control Testing module",
        defaultValue: false,
    },
};

// On the following domains "showFeatureFlipper" is enabled by default.
const SHOW_FEATURE_FLIPPER_DEFAULT_DOMAINS = [
    "impero-dev.localhost",
    "testfoss.staging.next.imperocloud.dk",
    "testfoss.preproduction.rc.imperocloud.dk",
];

interface FeatureDeclarationData {
    description: string,
    defaultValue?: boolean | (() => boolean),
}

export interface Feature {
    key: FeatureKey,
    description: string,
    isEnabled: boolean,
}

function resolveDefaultValue(value: undefined | boolean | (() => boolean)): boolean {
    if (value === undefined) {
        return false;
    } else if (isBoolean(value)) {
        return value;
    } else if (isFunction(value)) {
        return value();
    } else {
        throw new Error(`Cannot resolve default feature value for: ${value}`);
    }
}


// Namespaced localStorage for flipper feature toggles.
//
type FlipperStorage = Storage<"localStorage", "flipper", FeatureKey, boolean>;
const flipperStorage: FlipperStorage = new Storage({
    type: "localStorage",
    namespace: "flipper",
    codecs: {
        encodeKey: (k: FeatureKey) => k,
        encodeValue: (isActive: boolean) => JSON.stringify(isActive),
        decodeValue: (json: string) => JSON.parse(json) as boolean,
    },
});

class Flipper {
    storage: FlipperStorage;
    cache: Partial<Record<FeatureKey, boolean>>;

    constructor(storage: FlipperStorage) {
        this.storage = storage;
        this.cache = {};
    }

    isEnabled(key: FeatureKey): boolean {
        const cachedValue = this.cache[key];

        if (cachedValue !== undefined) {
            return cachedValue;
        } else {
            const defaultValue: boolean = resolveDefaultValue(FEATURES[key].defaultValue);
            const value = this.storage.getOr(key, defaultValue);
            this.cache[key] = value;
            return value;
        }
    }

    set(feature: FeatureKey, isEnabled: boolean) {
        this.cache[feature] = isEnabled;
        this.storage.set(feature, isEnabled);
    }

    getAll(): Array<Feature> {
        return Object.entries(FEATURES)
            .filter(([key]) => !(!IS_DEV_ENV && DEV_ONLY_FEATURES.includes(key)))
            .map(([keyStr, data]) => {
                const key = keyStr as FeatureKey;
                const { description } = data;
                const isEnabled = this.isEnabled(key);
                return { key, description, isEnabled };
            });
    }

    setAll(features: Record<FeatureKey, boolean>): void {
        Object.entries(features).forEach(([keyStr, isEnabled]) => {
            const key = keyStr as FeatureKey;
            this.set(key, isEnabled);
        });
    }
}

export const flipper = new Flipper(flipperStorage);
