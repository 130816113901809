// Storage is an abstraction that allows to build type-safe namespaced storages on top of localStorage or sessionStorage.
// For a usage example please see frontend/spa/src/org/pageState/storage.ts.

import { assertNever } from "utils/obj";

interface StorageCodecs<K, V> {
    encodeKey: (key: K) => string,
    encodeValue: (value: V) => string,
    decodeValue: (str: string) => V,
}

// The only single place to declare namespaces.
type Namespace = "pageState" | "flipper" | "pendingActivities";

type BrowserStorageType = "localStorage" | "sessionStorage";
// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
type BrowserStorage = typeof window.localStorage | typeof window.sessionStorage;

function getBrowserStorageByType(browserStorageType: BrowserStorageType): BrowserStorage {
    switch (browserStorageType) {
        case "localStorage": return window.localStorage;
        case "sessionStorage": return window.sessionStorage;
        default: return assertNever(browserStorageType);
    }
}

interface StorageOptions<S extends BrowserStorageType, N extends Namespace, K, V> {
    type: S,
    namespace: N,
    codecs: StorageCodecs<K, V>,
}

class Storage<S extends BrowserStorageType, N extends Namespace, K, V> {
    private browserStorage: BrowserStorage;
    private browserStorageType: S;
    private namespace: N;
    private codecs: StorageCodecs<K, V>;

    constructor(options: StorageOptions<S, N, K, V>) {
        const { type: browserStorageType, namespace, codecs } = options;

        this.browserStorageType = browserStorageType;
        this.namespace = namespace;
        this.browserStorage = getBrowserStorageByType(browserStorageType);
        this.codecs = codecs;
    }

    set(key: K, value: V) {
        const globalKey: string = this.buildGlobalKey(key);
        const encodedValue: string = this.codecs.encodeValue(value);
        this.browserStorage.setItem(globalKey, encodedValue);
    }

    get(key: K): V | null {
        const globalKey: string = this.buildGlobalKey(key);
        const encodedValue = this.browserStorage.getItem(globalKey);
        if (encodedValue === null) { return null; }
        try {
            return this.codecs.decodeValue(encodedValue);
        } catch (error) {
            console.error(`Failed to decode value from key "${globalKey}" of ${this.browserStorageType}: `, error);
            return null;
        }
    }

    getOr(key: K, defaultValue: V): V {
        const fetchedValue = this.get(key);
        if (fetchedValue !== null) {
            return fetchedValue;
        } else {
            return defaultValue;
        }
    }

    private buildGlobalKey(key: K): string {
        const localKey: string = this.codecs.encodeKey(key);
        return `${this.namespace}:${localKey}`;
    }
}

export {
    Storage,
};
